export const COMMUNITY_USER_CONNECTION_LOOKUP_FRAGMENT = `
  fragment communityUserConnectionLookupFragment on CommunityUser {
    uid
    name
    _ourConnection(myUid: "%authUser%") {
        uid
        linkState
        user {
            uid
            name
        }
        connectedUser {
            uid
            name
        }
    }
  }
`;
